
.padding-section{
	padding: 1.8rem;
	box-sizing: border-box;
	height: 100vh;
	@media (min-width: 900px) {
		padding: 2.3rem;
	}
}
.page-close{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	border: 4px solid #DBCAC5;
	box-sizing: border-box;
	filter: drop-shadow(3px 3px 4px #DBCAC5);
	@media (min-width: 900px) {
		font-size: 5.5rem;
	}
	h1{
		text-transform: none;
		color: #030305;
		font-weight: 600;
	}
}
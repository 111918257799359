/* variables */
.clearfix, .wrapper:after { display: block; clear: both; content: ''; }

sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }

button, input, optgroup { color: inherit; font: inherit; margin: 0; }

.transitions { transition: all .3s ease; }

.flex-between { display: flex; justify-content: space-between; align-items: center; }

.wrapper { /*max-width: 1430px;*/ margin: 0 auto; min-width: 320px; box-sizing: border-box; overflow: hidden; }

.vAlignMiddle { display: inline-block; vertical-align: middle; }

.box { transform: rotate(30deg); }

.wrapper-holder { width: 100%; max-width: 920px; margin: 0 auto; padding: 0 1rem; box-sizing: border-box; }

/*mixins*/
body { -webkit-text-size-adjust: 100%; }

input[type=submit], input[type=text], input[type=password], input[type=file], textarea { -webkit-appearance: none; }

textarea, input[type=text] { -webkit-border-radius: 0; }

input[type="image"] { padding: 0; border: none; }

img { border: none; }

form, fieldset { margin: 0; padding: 0; border-style: none; }

input, textarea, select { vertical-align: middle; color: #000; }

label { vertical-align: middle; }

input:focus { outline: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary { display: block; }

figure { margin: 1em 40px; }

audio { display: inline-block; vertical-align: baseline; }

audio:not([controls]) { display: none; height: 0; }

canvas { display: inline-block; vertical-align: baseline; }

progress { display: inline-block; vertical-align: baseline; }

video { display: inline-block; vertical-align: baseline; }

[hidden] { display: none; }

template { display: none; }

abbr[title] { border-bottom: 1px dotted; }

b { font-weight: bold; }

strong { font-weight: bold; }

dfn { font-style: italic; }

img { border: 0; border: none; max-width: 100%; }

hr { box-sizing: content-box; height: 0; }

small { font-size: 80%; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

button { overflow: visible; text-transform: none; cursor: pointer; }

input { line-height: normal; outline: none; }

input:focus { border-color: #BFA895; }

optgroup { font-weight: bold; }

input[type="reset"] { cursor: pointer; }

input[type="submit"] { cursor: pointer; }

button[disabled] { cursor: default; }

button::-moz-focus-inner { border: 0; padding: 0; }

input::-moz-focus-inner { border: 0; padding: 0; }

input[type="checkbox"] { box-sizing: border-box; padding: 0; }

input[type="radio"] { box-sizing: border-box; padding: 0; }

input[type="number"]::-webkit-inner-spin-button { height: auto; }

input[type="number"]::-webkit-outer-spin-button { height: auto; }

input[type="search"] { box-sizing: content-box; }

fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }

legend { border: 0; padding: 0; }

table { border-collapse: collapse; border-spacing: 0; }

td { padding: 0; }

th { padding: 0; }

ul { margin: 0; list-style-type: none; padding: 0; }

@font-face { font-family: 'Noto Sans SemBd'; font-display: auto; src: url("../fonts/hinted-NotoSans-SemiBold.woff2") format("woff2"), url("../fonts/hinted-NotoSans-SemiBold.woff") format("woff"); font-weight: 600; font-style: normal; }

html { font-family: "Noto Sans", Verdana, Arial, sans-serif; font-size: 62.5%; }

html input[type="button"] { cursor: pointer; }

html input[disabled] { cursor: default; }

@media only screen and (max-width: 1080px), only screen and (max-device-width: 1080px) { html { font-size: 9px; } }

@media only screen and (max-width: 800px), only screen and (max-device-width: 800px) { html { font-size: 8px; } }

body { font-family: "Noto Sans", Verdana, Arial, sans-serif; font-size: 1.8rem; line-height: 1.25; margin: 0; color: #34343C; min-width: 320px; background: #fff; }

a { background-color: transparent; color: #796CCD; text-decoration: underline; }

a:active { outline: 0; }

a:hover { outline: 0; text-decoration: none; }

h1, h2, h3 { font-size: 4rem; margin: 0 0 2.5rem; font-family: "Noto Sans", Verdana, Arial, sans-serif; text-transform: uppercase; color: #BFA895; font-weight: normal; }

p { margin: 0 0 1.3rem; }

.padding-section { padding: 1.8rem; box-sizing: border-box; height: 100vh; }

@media (min-width: 900px) { .padding-section { padding: 2.3rem; } }

.page-close { width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; font-size: 3rem; border: 4px solid #DBCAC5; box-sizing: border-box; filter: drop-shadow(3px 3px 4px #DBCAC5); }

@media (min-width: 900px) { .page-close { font-size: 5.5rem; } }

.page-close h1 { text-transform: none; color: #030305; font-weight: 600; }

//@extend-elements
%extend_1 {
  display: block;
  clear: both;
  content: '';
}

//sub, sup
%extend_2 {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

//button, input, optgroup, select, textarea
%extend_3 {
  color: inherit;
  font: inherit;
  margin: 0;
}

//original selectors
%extend_5 {
  clip: auto;
  clip-path: none;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

// gradient for flip-clock
%gradient-flip-clock {
  background-color: #ded9d5;
  background-image: linear-gradient(to top, #ded9d5, #ffffff);
}

.transitions {
  transition: all .3s ease;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clearfix {
  @extend %extend_1;
}

.wrapper {
  /*max-width: 1430px;*/
  margin: 0 auto;
  min-width: 320px;
  box-sizing: border-box;
  overflow: hidden;
  &:after {
    @extend %extend_1;
  }
}
.vAlignMiddle {
  display: inline-block;
  vertical-align: middle;
}
.box {
  transform: rotate(30deg);
}

.wrapper-holder {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing:border-box;
}

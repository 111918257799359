/* variables */
//colors
$white: #fff;
$color_crimson_approx: #d3203d;
$color_mandy_approx: #dd4b5c;
$color_red: #C70A2A;
$gray: #808080;
$yellow: #ff0;
$black: #000;
$silver: #c0c0c0;
$color_tapa_approx: #757575;
$color_science_blue_approx: #0071dc;
$color_downy_approx: #6cd6ac;
$black_26: rgba(0, 0, 0, 0.26);
$color_red_ribbon_approx: #eb0433;
$black_20: rgba(0, 0, 0, 0.2);
$color_geraldine_approx: #ff9090;
$color_concord_approx: #7d7d7d;
$color_nobel_approx: #b2b2b2;
$color_f24_block: #1E1D29;
$color_f24_corner: #FFC524;
$color_heading: #24222D;
$color_main: #34343C;
$color_template: #BFA895;
$color_sucess: #00a550;
$color_winter_blue: #206FB9;
$bg_btn: #796CCD;

//fonts
$font_semi_bold: 'Noto Sans', Verdana, Arial, sans-serif;

//urls
$url_8: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAYAAAB24g05AAAA4UlEQVQokZ2PoUpEURRF7xTBJIiTphiMgkWwWE3m1wSbJsPlhXfvOWsjfsCAfzA/MH9gdYrVqJgsBoNBw4A8y0VkGIf3Ztdz1mbtENZIjHEbmAGXvWF3HwGPklp3n/SCU0p7wIukVtI4hDDoDEs6kPQmqQVyX+1jSR+SviVdLB4PJd38p2Nmp8AXMHf3apnafdk0jTFuLtzOgLmkTzM7WarXNM0WcFe2zeq63gkhBOCqFL/nnI9WbqyqasPdJwV4Am5L4auZ7a+E/2QAXJeSFnhOKe12hX/j7ueSHnLOwy7/P4aIdi7KCY4iAAAAAElFTkSuQmCC);
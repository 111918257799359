@import "fonts";
html {
  font-family: $font_semi_bold;
  font-size: 62.5%;
  input[type="button"] {
    cursor: pointer;
  }
  input[disabled] {
    cursor: default;
  }
  @media only screen and(max-width: 1080px),only screen and(max-device-width: 1080px) {
    font-size: 9px;
  }
  @media only screen and(max-width: 800px),only screen and(max-device-width: 800px) {
    font-size: 8px;
  }
}
body {
  font-family:$font_semi_bold;
  font-size: 1.8rem;
  line-height: 1.25;
  margin: 0;
  color: $color_main;
  min-width: 320px;
  background:#fff;
}
a {
  background-color: transparent;
  color: $bg_btn;
  text-decoration: underline;
  &:active {
    outline: 0;
  }
  &:hover {
    outline: 0;
    text-decoration: none;
  }
}
h1, h2, h3 {
  font-size: 4rem;
  margin: 0 0 2.5rem;
  font-family:$font_semi_bold;
  text-transform: uppercase;
  color: $color_template;
  font-weight: normal;
}


p {
  margin: 0 0 1.3rem;
}